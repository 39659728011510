import { bollinger } from "../indicators/bollinger";
import { MACD } from "../indicators/macd";
import { calculateMovingAverageSeriesData } from "../indicators/movingAverageSimple";
import { parabolicSAR } from "../indicators/parabolicSAR";
import { MOVING_AVERAGE_DEFAULT_PERIOD, BOLLINGER_DEFAULT_PERIOD, BOLLINGER_DEFAULT_STD, MACD_FAST_PERIOD, MACD_SLOW_PERIOD, MACD_SIGNAL_PERIOD, MACD_SOURCE, MACD_COLORS, PARABOLIC_SAR_DEFAULT_ACC, PARABOLIC_SAR_DEFAULT_MAX, PARABOLIC_SAR_DEFAULT_PERIOD } from "./chart-defaults";


export const INDICATORS_INITIAL_CONFIG = {
  movingAvg: {
    label: "Moving Average Simple", enable: false, func: calculateMovingAverageSeriesData,
    ref: null, data: [], seriesType: 'line',
    params: { period: MOVING_AVERAGE_DEFAULT_PERIOD },
    seriesOptions: { color: 'rgb(242, 142, 44)', lineWidth: 1 }
  },
  bollinger: {
    label: "Bollinger", enable: false, func: bollinger, seriesType: { ml: 'line', bl: 'line', tl: 'line' },
    mlRef: null, blRef: null, tlRef: null, data: { ml: [], bl: [], tl: [] },
    params: { period: BOLLINGER_DEFAULT_PERIOD, std: BOLLINGER_DEFAULT_STD },
    seriesOptions: {
      tl: { color: '#2962FF', lineWidth: 1 },
      ml: { color: 'rgb(87, 225, 90)', lineWidth: 1 },
      bl: { color: '#2962FF', lineWidth: 1 },
    }
  },
  macd: {
    label: "MACD", enable: false, func: MACD,
    MACDRef: null, signalEMARef: null, seriesType: { signalEMA: 'line', MACD: 'histogram' },
    data: { MACD: [], fastEMA: [], slowEMA: [], signalEMA: [], histogram: [] },
    params: {
      fastPeriod: MACD_FAST_PERIOD,
      slowPeriod: MACD_SLOW_PERIOD,
      signalPeriod: MACD_SIGNAL_PERIOD,
      applyTo: MACD_SOURCE,
      colors: MACD_COLORS
    },
    priceOption: {
      scaleMargins: {
        top: 0.8,
        bottom: 0.005
      }
    },
    seriesOptions: {
      MACD: {
        priceScaleId: 'macd',
        color: '#2962FF',
        lineWidth: 0.9,
        priceLineVisible: false,
        priceFormat: {
          type: 'price',
          precision: 6
        }
      },
      signalEMA: {
        priceScaleId: 'macd',
        color: '#2962FF',
        lineWidth: 0.9,
        priceLineVisible: false,
        priceFormat: {
          type: 'volume',
          precision: 6
        }
      }
    }
  },
  parabolicSAR: {
    label: "Parabolic SAR", enable: false, func: parabolicSAR,  // This is the new indicator function
    ref: null, data: [], seriesType: 'line',
    params: { 
      acceleration: PARABOLIC_SAR_DEFAULT_ACC,  // Acceleration factor (usually between 0.02 and 0.2)
      maximum: PARABOLIC_SAR_DEFAULT_MAX,  // Maximum acceleration factor (default is usually 0.2)
      period: PARABOLIC_SAR_DEFAULT_PERIOD  // The period (in some cases, this can be calculated or may not be used)
    },
    seriesOptions: {
      color: '#FF4081',  // Color of the Parabolic SAR line, change as needed
      lineWidth: 0.9,
      shape: 'circle',  // Shape of the Parabolic SAR points, can be circle, triangle, etc.
      fillColor: 'rgba(255, 64, 129, 0.6)'  // Optional: Fill color if the marker has one
    }
  },
};

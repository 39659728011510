// Chart general defaults
export const CHART_DEFAULTS = {
  theme: 'Dark',
  layout: {
    backgroundColor: '#131722',
    textColor: '#d1d4dc',
    fontSize: 12,
    fontFamily: 'Arial',
  },
  grid: {
    vertLines: {
      color: '#2B2B43',
    },
    horzLines: {
      color: '#363C4E',
    },
  },
  crossHair: {
    vertLine: {
      color: '#6A5ACD',
      width: 0.5,
      style: 0,
    },
    horzLine: {
      color: '#6A5ACD',
      width: 0.5,
      style: 0,
    },
  },
};

// INDICATORS DEFAULT PARAMS
export const MOVING_AVERAGE_DEFAULT_PERIOD = 10;
export const BOLLINGER_DEFAULT_PERIOD = 20;
export const BOLLINGER_DEFAULT_STD = 2;

export const MACD_FAST_PERIOD = 12;
export const MACD_SLOW_PERIOD = 26;
export const MACD_SIGNAL_PERIOD = 9;
export const MACD_SOURCE = 'close';
export const MACD_COLORS = {
  positive: 'rgb(87, 225, 90)',
  negative: 'rgb(242, 142, 44)',
  signalLine: '#2962FF',
};

export const PARABOLIC_SAR_DEFAULT_ACC = 0.02;  // Acceleration factor
export const PARABOLIC_SAR_DEFAULT_MAX = 0.2;   // Maximum acceleration factor
export const PARABOLIC_SAR_DEFAULT_PERIOD = 0;  // Period (optional, typically not directly used in SAR calculations)

// IN SECONDS
export const ONE_MINUTE = 60;
export const ONE_HOUR = 60 * ONE_MINUTE;
export const ONE_DAY = 24 * ONE_HOUR;
export const ONE_MONTH = 30 * ONE_DAY;

import {
    PARABOLIC_SAR_DEFAULT_ACC,
    PARABOLIC_SAR_DEFAULT_MAX,
    PARABOLIC_SAR_DEFAULT_PERIOD
} from "../constants/chart-defaults";

export function parabolicSAR(candleData, parabolicSARData, params = {
    acceleration: PARABOLIC_SAR_DEFAULT_ACC,
    maximum: PARABOLIC_SAR_DEFAULT_MAX,
    period: PARABOLIC_SAR_DEFAULT_PERIOD
  }) {
    let { acceleration, maximum, period } = params;
  
    // Ensure that we have data to work with
    if (candleData.length === 0) {
      return;
    }
    // Validate period
    if (!period) {
      period = PARABOLIC_SAR_DEFAULT_PERIOD;
    }
  
    parabolicSARData.length = 0; // Reset the data array
  
    let trend = 1; // 1 for uptrend, -1 for downtrend
    let SAR = 0;
    let AF = acceleration; // Acceleration factor
    let EP = 0; // Extreme point (highest high or lowest low)
  
    // Ensure that we have enough data to calculate SAR
    if (candleData.length < period) {
      return; // Not enough data to calculate SAR
    }
  
    // Initialize the first SAR and EP based on the first candle
    if (candleData[0].high > candleData[0].low) {
      EP = candleData[0].high; // For uptrend, EP is the highest high
      trend = 1; // Uptrend
    } else {
      EP = candleData[0].low; // For downtrend, EP is the lowest low
      trend = -1; // Downtrend
    }
  
    // Initial SAR calculation: it should be below the price in uptrend and above in downtrend
    SAR = candleData[0].low - (acceleration * (candleData[0].high - candleData[0].low)); // SAR for uptrend
  
    // Loop through the candles to calculate SAR for each period
    for (let i = 1; i < candleData.length; i++) {
      const currentCandle = candleData[i];
  
      // Ensure SAR is calculated correctly within trend
      if (trend === 1) {
        // Uptrend calculation: SAR moves towards the extreme point
        SAR = SAR + AF * (EP - SAR);
  
        if (currentCandle.low < SAR) {
          // Trend change: downtrend
          trend = -1;
          EP = currentCandle.low; // Update EP to the lowest low for downtrend
          AF = acceleration; // Reset AF for downtrend
        } else {
          // Update EP in uptrend: it's the highest high
          if (currentCandle.high > EP) {
            EP = currentCandle.high;
            AF = Math.min(AF + acceleration, maximum); // Increment and cap AF
          }
        }
      } else {
        // Downtrend calculation: SAR moves towards the extreme point
        SAR = SAR + AF * (SAR - EP);
  
        if (currentCandle.high > SAR) {
          // Trend change: uptrend
          trend = 1;
          EP = currentCandle.high; // Update EP to the highest high for uptrend
          AF = acceleration; // Reset AF for uptrend
        } else {
          // Update EP in downtrend: it's the lowest low
          if (currentCandle.low < EP) {
            EP = currentCandle.low;
            AF = Math.min(AF + acceleration, maximum); // Increment and cap AF
          }
        }
      }
  
      // Log SAR values for debugging
    //   console.log(`Candle ${i}: SAR = ${SAR}, AF = ${AF}, EP = ${EP}, Trend = ${trend}`);
  
      // Push the SAR value to the data array for rendering
      parabolicSARData.push({ time: currentCandle.time, value: SAR });
    }
  }
  